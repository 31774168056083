<template>
  <component :is="commonComponent" id="blog" title="Blogs">
    <section id="blog-content" class="pc_padding">
      <h1 class="m-b-30 head-1">
        The Book of Answers | Open the Book of Answers to Resolve Your Doubts
      </h1>

      <div class="autor m-b-30">
        <div>Author: Momo </div>
        <div>Publish: 2024/9/10 </div>
      </div>
      <div class="slish m-b-20" />

      <AdComponent ref="ads-blog-1" class="m-b-40 " :ads="adsensConfig.blog_1"
        :showDebug="showDebug" />

      <p>
        In our fast-paced lives, we often face the dilemma of making choices. The author has
        thoughtfully summarized the following excerpts from the Book of Answers, hoping that they
        will guide you to find your life&#39;s North Star. This article is especially for new
        university students.
      </p>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/NWI1NDQxNDgwYjdiMDYwMjU2NDA5MWU2ZjI3ODEwMGVfQUZlNkc2ZHI3Z2UyUDhkWVV4N0lJcnI3T1UyTUN3N2VfVG9rZW46VzBhWWJxVU9pbzRqSHB4RFBjYmNPY3d0bnlnXzE3MjYyMTExNDY6MTcyNjIxNDc0Nl9WNA.jpg">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Reflect as You Go
      </h2>

      <AdComponent ref="ads-blog-2" class="m-b-40 " :ads="adsensConfig.blog_2"
        :showDebug="showDebug" />

      <p>
        If you find yourself drifting through your days, unsure of what you want to do or what you
        are pursuing, it likely means you lack goals and plans for your life.
      </p>

      <p>
        In such moments, it&#39;s better to set aside your current tasks and engage in more
        meaningful activities within your limited time. Read a book, watch a series, play an
        exhilarating game of sports, enjoy a hearty meal, climb a mountain, or take in a scenic
        view. Reflect through action and gain insights through growth. You will likely develop a new
        understanding.
      </p>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/MDQ5YTc5ODRhZjUzMjkwNzUyN2YzNTEwZTcyMWJkNTlfMDRDOElXcWUwUHA1OTA2dEU2M1pNdGdzM3UyQUdyRG1fVG9rZW46UFFlbmJDRzZzbzZTSVV4NWczaWNSZFhjbnNjXzE3MjYyMTExNDY6MTcyNjIxNDc0Nl9WNA.webp">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Learn to Wait
      </h2>

      <p>
        Didn&#39;t get elected as a class officer at the start of the term? Couldn&#39;t join the
        club you wanted? Are your grades not meeting your expectations? Feeling anxious about your
        body or appearance? As the saying goes, &quot;Haste makes waste.&quot; Rushing often leads
        to less satisfactory results.
      </p>

      <p>
        Instead, calm your mind and wait patiently. Face all unknowns with a composed attitude.
        Accumulate experience quietly; slow and steady wins the race. Waiting is about seizing the
        right moment. We need to endure loneliness and temptation, withstand pressure and ridicule.
        While waiting may not guarantee hope, hope always involves waiting.
      </p>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/YWIwMjljNmUxMTg4MGE5OTljMGU0MmJkYjQ0YzMxYTBfd0NiMWtpd3ZUd1YzNXpBS21KeVZMSFRsNTlPb2tPOGVfVG9rZW46SlVjcGIyNDNPb0FoeG14MjBmdmMzc2xIblBXXzE3MjYyMTExNDY6MTcyNjIxNDc0Nl9WNA.jpg">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Go with the Flow
      </h2>

      <p>
        Entering university can feel unfamiliar. In a new environment, everything might seem
        awkward—new roommates you don&#39;t get along with, a major you&#39;re not satisfied with.
      </p>

      <p>
        But don&#39;t over-stress. Adjust your emotions and face life optimistically. Focus on what
        needs to be done. You can vent your feelings by keeping a journal. Take each day as it
        comes, naturally. Don&#39;t let anxiety and unease overshadow the happiness and joy that
        university life should bring.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Dare to Explore
      </h2>

      <p>
        What if you have social anxiety? Everyone experiences moments of discomfort in life, like a
        first date, a public speech, sweaty palms, a racing heart, or stumbling over words.
      </p>

      <p>
        These are normal physiological responses, your body&#39;s way of activating its
        &quot;fight&quot; mechanism. Socially anxious individuals often fear criticism and
        embarrassment, leading to a continuous avoidance of social interactions. However, avoiding
        social interactions can result in isolation and loneliness, making it hard to find the right
        way to overcome these fears. Therefore, dare to explore in life. Start by greeting people
        around you or answering questions in class. Engage more with socially adept friends, and
        gradually adapt to socializing through their influence.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Learn to Break Down Tasks
      </h2>

      <p>
        Procrastination comes in many forms, one of which is &quot;distraction
        procrastination.&quot; This occurs when you can&#39;t control your curiosity and fail to
        focus, knowing you have tasks to do but delaying starting them.
      </p>

      <p>
        In such cases, try the breakdown method. Prioritize tasks based on their urgency and
        importance. Do the important and urgent ones first, followed by important but not urgent
        tasks. The non-urgent and unimportant ones can naturally be left for last. Using this
        method, you won&#39;t feel like you&#39;ve been busy for nothing, and your procrastination
        habits can be effectively alleviated.
      </p>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/MmE4MWY4YWQ3YmQ1MmExYjM1MmQxN2ZlMDM0MGNiY2NfTnZNTm9HV21sRElYcTY2NVE4RU5lVXJCOUtYVzJlSkhfVG9rZW46STJXM2JDTTdHb1JZaUR4b012TWNlVlNubjhlXzE3MjYyMTExNDY6MTcyNjIxNDc0Nl9WNA.png">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Relax Appropriately
      </h2>

      <p>
        Almost everyone today experiences anxiety, caught in the relentless competition of society.
        However, most people are ordinary. Everything needs rest to function better. Life requires a
        balance of tension and relaxation. Only by relaxing appropriately can we move forward more
        effectively.
      </p>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img"
          src="@/assets/ZTg0NWM2ZWI1OTc0YzZlZjQ1ZDI5M2U2OGFhYmJjYWFfSmVCVTFvaDNMamRxWHFwYnlicXhBbXIzdkZLSTM0ZmZfVG9rZW46UGRLaWJIVVpNb0dIUlR4dnZOZ2N2NDJCblVaXzE3MjYyMTExNDY6MTcyNjIxNDc0Nl9WNA.jpg">
      </p>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  name: 'Blog1',
  components: {
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Open the Book of Answers to Resolve Your Doubts',
      meta: [
        {
          name: 'description',
          content: "In our fast-paced lives, we often face the dilemma of making choices. The author has thoughtfully summarized the following excerpts from the Book of Answers, hoping that they will guide you to find your life's North Star. This article is especially for new university students.",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",

    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end
  },
};
</script>
